<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
           <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('warehouse_service.registration_recommendation') }}</h4>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="table_head" striped bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(warehouse_name)="data">
                      <span class="capitalize">{{ data.item.warehouse_name }}</span>
                    </template>
                    <template v-slot:cell(warehouse_type_name)="data">
                      <span class="capitalize">{{ data.item.warehouse_type_name }}</span>
                    </template>
                    <template v-slot:cell(upazilla_name)="data">
                      <span class="capitalize">{{ data.item.upazilla_name }}</span>
                    </template>
                    <template v-slot:cell(land_area)="data">
                      {{ $n(data.item.land_area, { useGrouping: false }) }}
                    </template>
                    <template v-slot:cell(status)="data">
                      {{ data.item.status ? $t('globalTrans.inactive') : $t('globalTrans.active') }}
                    </template>
                    <template v-slot:cell(view)="data">
                      <a href="javascript:" class="btn_table_action table_action_view" title="View Recommentation List" v-b-modal.modal-5 size="sm" @click="setRow(data.item)"><i class="fas fa-eye"></i></a>
                    </template>
                    <template v-slot:cell(action)="data">
                      <a href="javascript:" class="btn_table_action table_action_status" title="Recommendent" size="sm" @click="remove(data.item)"><i class="far fa-comment-dots"></i></a>
                    </template>
                  </b-table>
                  <b-pagination
                    align="center"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="testClick"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-5" size="lg" :title="$t('warehouse_service.all_registration_list')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <DetailsView :id="testId"/>
    </b-modal>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import RestApi, { authServiceBaseUrl, warehouseServiceBaseUrl } from '@/config/api_config'
import { RegistrationRecommendationList, RegRecomChangeStatus, sendNotification } from '../../api/routes'
import DetailsView from './DetailsView'

export default {
  name: 'UiDataTable',
  components: {
    DetailsView
  },
  data () {
    return {
      pagination: {
        currentPage: 1,
        totalRows: 0,
        perPage: this.$store.state.commonObj.perPage,
        slOffset: 1
      },
      testId: 0,
      rows: [],
      regionLists: [],
      districtList: [],
      upazilaList: [],
      unionList: [],
      warehouseNameList: [],
      formData: {
        comments: '',
        comments_bn: ''
      }
    }
  },
  computed: {
   formTitle () {
      return (this.testId === 0) ? this.$t('warehouse_service.e-registration_entry') : this.$t('e-registration_entry.e-registration_entry')
    },
    authUser () {
      return this.$store.state.Auth.authUser
    },
    loading () {
      return this.$store.state.commonObj.loading
    },
    listReload () {
      return this.$store.state.commonObj.listReload
    },
    loadingState () {
      if (this.listReload) {
        return true
      } else if (this.loading) {
        return true
      } else {
        return false
      }
    },
    listData () {
      return this.$store.state.WarehouseService.recommendations
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('warehouse_information.district'), class: 'text-center' },
          { label: this.$t('warehouse_information.upazilla'), class: 'text-center' },
          { label: this.$t('warehouse_service.union'), class: 'text-center' },
          { label: this.$t('warehouse_config.warehouse_name'), class: 'text-center' },
          { label: this.$t('warehouse_service.name'), class: 'text-center' },
          { label: this.$t('warehouse_service.father_name'), class: 'text-center' },
          { label: this.$t('warehouse_service.land_area'), class: 'text-center' },
          { label: this.$t('warehouse_service.address'), class: 'text-center' },
          { label: this.$t('warehouse_service.view'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'distict_name_bn' },
          { key: 'upzila_name_bn' },
          { key: 'union_name_bn' },
          { key: 'warehouse_name_bn' },
          { key: 'name_bn' },
          { key: 'father_name_bn' },
          { key: 'land_area' },
          { key: 'address_bn' },
          { key: 'view' },
          { key: 'action' }
        ]
      } else {
        keys = [
        { key: 'index' },
          { key: 'distict_name' },
          { key: 'upzila_name' },
          { key: 'union_name' },
          { key: 'warehouse_name' },
          { key: 'name' },
          { key: 'father_name' },
          { key: 'land_area' },
          { key: 'address' },
          { key: 'view' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList
    },
    warehouseTypeList: function () {
      return this.$store.state.warehouse.warehouseTypeList
    }
  },
  watch: {
    loadingState: function (newVal) {
      if (newVal) {
        this.loadData()
      }
    }
  },
  created () {
    this.loadData()
  },
  mounted () {
    core.index()
  },
  methods: {
    resetId () {
      this.testId = 0
    },
    testClick () {
      this.loadData()
    },
    default () {
      return {
        id: this.rows.length,
         name: '',
          name_bn: '',
          email: '',
          password: '',
          password_confirmation: '',
          user_type_id: '',
          mobile_no: '',
          service_type: '',
          service_type_bn: '',
          division_id: 0,
          region_id: 0,
          district_id: 0,
          upazilla_id: 0,
          union_id: 0,
          warehouse_type_id: 0,
          warehouse_id: 0,
          address: '',
          address_bn: '',
          father_name: '',
          father_name_bn: '',
          land_type_id: 0,
          land_area: ''
      }
    },
    paginationData (data) {
      this.pagination.currentPage = data.current_page
      this.pagination.totalRows = data.total
      this.pagination.slOffset = this.pagination.perPage * this.pagination.currentPage - this.pagination.perPage + 1
    },
    searchData () {
      this.loadData()
    },
    async loadData () {
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
      const warehouseId = this.authUser.warehouse_id
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage, warehouse_id: warehouseId })
      const result = await RestApi.getData(authServiceBaseUrl, RegistrationRecommendationList, params)
      if (result.success) {
        this.$store.dispatch('WarehouseService/addRegRecomm', this.relationalData(result.data.data))
        this.paginationData(result.data)
      }
       this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    relationalData (data) {
      const recommendationData = data.map(item => {
        const districtData = this.$store.state.commonObj.districtList.find(listitem => listitem.value === item.district_id)
        const upazilaData = this.$store.state.commonObj.upazilaList.find(listitem => listitem.value === item.upazilla_id)
        const unionData = this.$store.state.commonObj.unionList.find(listitem => listitem.value === item.union_id)
        const wareHouseData = this.$store.state.warehouse.warehouseInfoList.find(listitem => listitem.value === item.warehouse_id)
        const tmpBindingData = {
          distict_name: districtData !== undefined ? districtData.text_en : '',
          distict_name_bn: districtData !== undefined ? districtData.text_bn : '',
          upzila_name: upazilaData !== undefined ? upazilaData.text_en : '',
          upzila_name_bn: upazilaData !== undefined ? upazilaData.text_bn : '',
          union_name: unionData !== undefined ? unionData.text_en : '',
          union_name_bn: unionData !== undefined ? unionData.text_bn : '',
          warehouse_name: wareHouseData !== undefined ? wareHouseData.text_en : '',
          warehouse_name_bn: wareHouseData !== undefined ? wareHouseData.text_bn : ''
        }
       return Object.assign({}, item, tmpBindingData)
      })
      return recommendationData
    },
    async sendNotification (notificationData) {
      const response = await RestApi.postData(warehouseServiceBaseUrl, sendNotification, notificationData)
      if (response.success) {
        // this.$socket.emit('send-notification', response.notification)
      }
    },
    async toggleStatus (item) {
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      await RestApi.putData(authServiceBaseUrl, `${RegRecomChangeStatus}/${item.id}`, this.formData).then(response => {
        if (response.success) {
          this.$store.dispatch('WarehouseService/toggleRegRecomStatus', item)
          this.sendNotification(response.notification_data)
          this.$toast.success({
            title: 'Success',
            message: 'Data Recommended Successfully',
            color: '#D6E09B'
          })
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
        } else {
            this.$toast.error({
              title: 'Error',
              message: 'Comments Required | Operation Failed'
            })
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        }
      }, error => {
        if (error) {
          //
        }
        this.$toast.error({
            title: 'Error',
            message: 'Failed to update status',
            color: '#D6E09B'
          })
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    setRow (item) {
      this.testId = item.id
    },
    remove (item) {
      const comments = this.$i18n.locale === 'bn' ? 'মন্তব্য' : 'Comments'
      const commentsBn = this.$i18n.locale === 'bn' ? 'মন্তব্য (বাংলা)' : 'Comments (bn)'
      this.$swal({
        title: this.$t('globalTrans.recommendentChangeMsg'),
        showCancelButton: true,
        html: `
        <div class="form-group text-left mt-3">
          <label for="formGroupExampleInput" class="small">${comments}</label>
          <textarea id="comments" v-model="formData.comments" class="swal2-input" rows="3"></textarea>
        </div>
        <div class="form-group text-left">
          <label for="formGroupExampleInput" class="small">${commentsBn}</label>
          <textarea id="comments_bn" v-model="formData.comments_bn" class="swal2-input" rows="3"></textarea>
        </div>
        `,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        focusConfirm: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.formData.comments = document.querySelector('#comments').value
          this.formData.comments_bn = document.querySelector('#comments_bn').value
          this.toggleStatus(item)
        }
      })
    }
  }
}
</script>
